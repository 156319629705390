<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">量规分类列表</div>
		</div>
		<div class="buttonview">
			<el-button type="primary" @click="isadd = true">添加量规分类</el-button>
		</div>
		<el-table :data="list" style="width: 100%" stripe border>
			<el-table-column type="index" align="center" width="50" />
			<el-table-column align="center" prop="categoryName" label="名称" />
			<el-table-column align="center" prop="categoryDesc" label="描述" />
			<el-table-column label='操作' align="center">
				<template #default="scope">
					<el-button size="small" type="primary" @click="edits(scope.row)">修改
					</el-button>
					<el-button size="small" type="danger" @click="deletes(scope.row)">删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="elpagination">
			<el-pagination background layout="prev, pager, next" @current-change="pagechange" :page-count="pages" />
		</div>
	</div>
	
	
	
	<el-dialog v-model="isadd" title="添加量规分类" width="20%">
		<div class="row">
			<div class="row-title">量规分类名称<el-tag type="danger" size="small">必填</el-tag></div>
			<el-input v-model="addjson.categoryName" placeholder="请输入要添加的量规分类名称" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">量规分类描述<el-tag type="danger" size="small">必填</el-tag></div>
			<el-input v-model="addjson.categoryDesc" placeholder="请输入量规分类描述" clearable type="text"></el-input>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isadd = false">取消</el-button>
				<el-button type="primary" @click="submitadd" :loading="loading">添加</el-button>
			</span>
		</template>
	</el-dialog>
	
	<el-dialog v-model="isedit" title="修改量规分类" width="20%">
		<div class="row">
			<div class="row-title">量规分类名称<el-tag type="danger" size="small">必填</el-tag></div>
			<el-input v-model="editjson.categoryName" placeholder="请输入要添加的量规分类名称" clearable type="text"></el-input>
		</div>
		<div class="row">
			<div class="row-title">量规分类描述<el-tag type="danger" size="small">必填</el-tag></div>
			<el-input v-model="editjson.categoryDesc" placeholder="请输入量规分类描述" clearable type="text"></el-input>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button @click="isedit = false">取消</el-button>
				<el-button type="primary" @click="submitedit" :loading="loading">修改</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script>
	import {
		rubriccategorieslist,
		rubriccategoriesadd,
		rubriccategoriesedit,
		rubriccategoriesdelete
	} from "../../util/api"
	export default {
		data() {
			return {
				pageNum: 1,
				pageSize: 15,
				pages: 1,
				list: null,
				isadd: false,
				isedit: false,
				addjson: {
					categoryDesc: null,
					categoryName: null
				},
				editjson: {
					id: null,
					categoryDesc: null,
					categoryName: null
				},
				loading: false
			}
		},
		created() {
			this.getlist()
		},
		methods: {
			submitedit(){
				if(!this.editjson.categoryName){
					this.$message({
						type: 'warning',
						message: '请输入名称',
					})
				}else if(!this.editjson.categoryDesc){
					this.$message({
						type: 'warning',
						message: '请输入描述',
					})
				}else{
					this.loading = true
					rubriccategoriesedit(this.editjson).then((res)=>{
						this.loading = false
						this.isedit = false
						this.$message({
							type: 'success',
							message: '修改成功',
						})
						this.getlist()
					}).catch((error)=>{
						this.loading = false
					})
				}
			},
			edits(row){
				this.editjson = {
					id: row.id,
					categoryDesc: row.categoryDesc,
					categoryName: row.categoryName
				}
				this.isedit = true
			},
			deletes(row){
				this.$confirm('是否删除量规分类“' + row.categoryName + '”？', '提示', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'error',
				}).then(() => {
					rubriccategoriesdelete(row.id).then((res)=>{
						this.$message({
							type: 'success',
							message: '删除成功',
						})
						this.getlist()
					})
				})
			},
			submitadd(){
				if(!this.addjson.categoryName){
					this.$message({
						type: 'warning',
						message: '请输入名称',
					})
				}else if(!this.addjson.categoryDesc){
					this.$message({
						type: 'warning',
						message: '请输入描述',
					})
				}else{
					this.loading = true
					rubriccategoriesadd(this.addjson).then((res)=>{
						this.loading = false
						this.isadd = false
						this.$message({
							type: 'success',
							message: '添加成功',
						})
						this.addjson = {
							categoryDesc: null,
							categoryName: null
						}
						this.getlist()
					}).catch((error)=>{
						this.loading = false
					})
				}
			},
			getlist() { //获取列表
				rubriccategorieslist({
					pageNum: this.pageNum,
					pageSize: this.pageSize
				}).then((res)=>{
					this.list = res.data.list
					this.pages = res.data.pages
				})
			},
			pagechange(index) {
				this.pageNum = index
				this.getlist()
			}
		}
	}
</script>

<style scoped>

</style>
